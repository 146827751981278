<template>
  <div class="LoginBJ">
    <!-- 菜单功能弹窗 -->
    <!-- <menuPop v-show="isOk" /> -->
    <div class="centenBox">
      <!-- 简历诊断左边布局区域 -->
      <div class="BoxLeft">
        <div class="leftTitle">
          <img v-if="platform === 'msyz'" src="@/assets/image/resumeLogin/msyz-logo.png" />
          <img v-if="platform === 'szzy'" src="@/assets/image/resumeLogin/digital-employment-logo.png" />
          <img v-if="platform === 'cqcsgl'" src="@/assets/image/resumeLogin/cqcsgl-logo.png" />
          <div class="LeftTextTitle">简历诊断</div>
        </div>
        <div class="leftTwo">
          <img src="@/assets/image/resumeLogin/icon.png" />
          <div class="leftText">简历写得好不好，一测就知晓</div>
        </div>
        <div class="leftTwo centen">
          <img src="@/assets/image/resumeLogin/icon(1).png" />
          <div class="leftText">诊断简历中的问题教你 突出简历亮点</div>
        </div>
        <div class="leftTwo">
          <img src="@/assets/image/resumeLogin/icon(2).png" />
          <div class="leftText">简历写得好，面试机会少不了</div>
        </div>
      </div>
      <!-- 简历诊断左边布局区域 -->
      <!-- 微信扫描登录右边区域 -->
      <div class="BoxRight">
        <div class="RightTitle">微信扫描二维码登录</div>
        <!-- 展示二维码区域 -->
        <div class="RightQRCode" v-loading="loading" element-loading-text="拼命加载中">
          <img
            :src="loginObj.qrcode"
            v-if="
              (qrStatusObj && qrStatusObj.is_expired === 1) ||
              (qrStatusObj && qrStatusObj.is_scan === 0) ||
              (qrStatusObj && qrStatusObj.is_expired === 3)
            "
          />
          <div
            class="success-box"
            v-if="qrStatusObj && qrStatusObj.is_expired === 0 && qrStatusObj.is_scan === 1"
          >
            <img src="@/assets/image/success-icon.png" />
            <div class="success-text">扫码成功</div>
            <div class="text">请在手机上确认 [ 授权登录 ]</div>
            <div class="refresh" @click="refreshFn">
              <span>刷新</span>
            </div>
          </div>
          <div class="qr-fixed" v-if="qrStatusObj && qrStatusObj.is_expired === 1">
            <div class="text">请重新刷新二维码</div>
            <div class="qr-Btn" @click="getLoginQrcodeFn">
              <button>点击刷新</button>
            </div>
          </div>
        </div>
        <!-- 展示二维码区域 -->
        <div class="RightBootom">
          <div class="RightBootomOne">
            <span>欢迎来到简历诊断</span>
            <span class="working">登录后解锁更多功能</span>
          </div>
          <div v-if="platform === 'msyz' || platform === 'szzy'" class="RightBootomTwo">
            <span>©2019-2023 桂ICP备19009194号</span>
            <span class="business">桂公网安备 45010802000184号</span>
          </div>
          <div v-if="platform === 'cqcsgl'" class="RightBootomTwo">
            <span>(渝ICP备12001925号)</span>
            <span class="business">邮编：401331 联系电话：(023)65626161（党政办）</span>
          </div>
        </div>
      </div>
      <!-- 微信扫描登录右边区域 -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { platform } from '@/api/config'
import menuPop from '@/components/menuPop'

export default {
  data() {
    return {
      platform,
      // isOk: false, //控制菜单的显示
      loading: true,
      channel: '', // 机器ID-渠道ID
      loginObj: null, // 登录二维码信息
      qrStatusObj: null, // 二维码情况信息
      timer: ''
    }
  },
  components: {
    menuPop //菜单功能弹窗
  },
  mounted() {
    // const _this = this;
    // this.channel = localStorage.getItem('channel');
    // if (this.$route.query.channel) {
    //   this.channel = this.$route.query.channel;
    //   this.setChannel(this.channel);
    // } else if (this.channel) {
    //   this.setChannel(this.channel);
    // } else {
    //   this.$message({
    //     message: '参数错误',
    //     type: 'error',
    //   });
    //   return false;
    // }
    // this.getLoginChannelVerifyFn()
    // if (!this.isOk) this.getLoginQrcodeFn()
    this.getLoginQrcodeFn()
    // this.isOk === false ? (this.loading = true) : (this.loading = false)
  },
  methods: {
    ...mapMutations({
      setChannel: 'SET_CHANNEL',
      setToken: 'SET_TOKEN',
      removerContents: 'REMOVER_CONTENTS'
    }),
    ...mapActions(['getLoginChannelVerify', 'getLoginQrcode', 'getLoginScanInfo', 'getUserInfo']),
    //点击小刷新
    refreshFn() {
      this.getLoginQrcodeFn()
    },
    // 获取机器-渠道校验参数
    async getLoginChannelVerifyFn() {
      const data = {
        channel: this.channel
      }
      const obj = await this.getLoginChannelVerify(data)
      if (obj.data.verify === 0) {
        this.channel = ''
        this.$message({
          message: obj.data.msg,
          type: 'error'
        })
      }
    },
    // 获取登录二维码
    async getLoginQrcodeFn() {
      this.loading = true
      // if (!this.channel) {
      //   this.$message({
      //     message: '参数错误',
      //     type: 'error'
      //   })
      //   return false
      // }
      this.loginObj = await this.getLoginQrcode({ channel: this.channel })
      this.loading = false
      this.getLoginScanInfoFn()
    },
    // 获取二维码状态信息(扫码获取状态)
    async getLoginScanInfoFn() {
      const data = {
        scene: this.loginObj.scene,
        channel: this.channel
      }
      this.qrStatusObj = await this.getLoginScanInfo(data)
      if (this.qrStatusObj.is_scan === 1 && this.qrStatusObj.token) {
        this.setToken(this.qrStatusObj.token)
        sessionStorage.setItem('token', this.qrStatusObj.token)
        // 调用获取用户信息的方法 方便全局取用户头像
        this.getUserInfo()
        // localStorage.setItem('token', this.qrStatusObj.token)
        // if (!this.channel) {
        //   this.$message({
        //     message: '参数错误',
        //     type: 'error'
        //   })
        //   return false
        // }
        this.loginObj = null
        this.qrStatusObj = null
        clearInterval(this.timer)
        // this.isOk = true
        this.$router.replace('/resume/index')
      } else {
        this.timingStart()
      }
    },
    // 开始计时
    timingStart() {
      if (this.qrStatusObj && this.qrStatusObj.is_expired === 1) {
        return clearInterval(this.timer)
      } else {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.getLoginScanInfoFn()
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer) //关闭定时器
  },
  created() {
    // const token = sessionStorage.getItem('token')
    // if (token !== null) this.isOk = true
    // 当网络请求返回401和不存在token的时候清除用户信息
    if (this.$route.query.go === 1) {
      this.$router.push({ query: {} })
      this.removerContents()
    }
  }
}
</script>

<style lang="stylus" scoped>
.LoginBJ
  width 100% vw
  height 100% vh
  z-index 99
  background url('~@/assets/image/resumeLogin/bg.png') no-repeat
  background-size 100% 100%
  background-attachment fixed
  position relative
  .centenBox
    width 730px
    height 534px
    background #FFFFFF
    border-radius 10px
    display flex
    position absolute
    left 44%
    top 22%
    box-shadow 0 0 18px 0 rgba(196, 214, 245, 0.4)
    border-radius 10px
    .BoxLeft
      box-sizing border-box
      width 242px
      height 534px
      background #F5FAFF
      padding 69px 33px 0 33px
      .leftTitle
        display flex
        align-items center
        margin-bottom 58px
        img
          width 42px
          height 42px
          margin-right 11px
        .LeftTextTitle
          font-size 24px
          font-weight bold
          color #333333
      .leftTwo
        display flex
        align-items center
        img
          width 26px
          height 27px
          margin-right 10px
        .leftText
          font-size 14px
          font-weight 400
          color #666666
      .leftTwo.centen
        margin 59px 0 60px 0
    .BoxRight
      flex 1
      .RightTitle
        font-size 22px
        font-family Source Han Sans CN
        font-weight 500
        color #333333
        text-align center
        margin-top 62px
      .RightQRCode
        position relative
        width 200px
        height 200px
        margin-left 144px
        margin-top 40px
        margin-bottom 50px
        text-align center
        .qr-fixed
          top 0
          left 0
          position absolute
          z-index 10
          background rgba(255, 255, 255, 0.9)
          cursor pointer
          width 100%
          height 100%
          display flex
          flex-direction column
          justify-content center
          .text
            font-size 20px
            color #333333
          .qr-Btn
            margin-top 16px
            button
              cursor pointer
              border none
              width 100px
              height 36px
              background #FFDD38
              border-radius 6px
              font-size 14px
              font-family Source Han Sans CN
              font-weight 500
              color #34271D
        .success-box
          width 100%
          height 100%
          img
            width 80px
            height 80px
            margin-left 63px
          .success-text
            font-size 24px
            margin 20px 0 20px 0
            color #00bb4b
          .text
            font-size 18px
            white-space nowrap
          .refresh:hover
            border-bottom 1px solid #003DBB
          .refresh
            cursor pointer
            font-size 14px
            font-family Source Han Sans CN
            font-weight 400
            color #003DBB
            position absolute
            top -104px
            right -118px
      .RightBootomOne
        margin-left 121px
        font-size 14px
        font-weight 400
        color #666666
        .working
          margin-left 10px
      .RightBootomTwo
        margin-top 9px
        margin-left 68px
        font-size 12px
        font-weight 400
        color #999999
        .business
          margin 0 10px 0 10px
</style>

<template>
  <div class="overlay">
    <!-- 菜单区域 -->
    <div class="menu-box">
      <div class="box">
        <div class="box-toptext">
          <div class="toptext-top">菜单功能选择</div>
          <div class="toptext-bottom">根据个人需要选择相应功能</div>
        </div>
        <div class="box-function">
          <div class="line-left"></div>
          <div class="function-text">功能选择</div>
          <div class="line-rigth"></div>
        </div>
        <!-- 功能选择区域 -->
        <div class="function-box">
          <div class="box-list" @click="skip('/resume/resume_template')">
            <div class="box-img">
              <img src="@/assets//image/resumeLogin/形状 6.png" alt="" />
            </div>
            <div class="box-text">简历模板</div>
          </div>

          <div class="box-list" @click="skip('/resume/edit')">
            <div class="box-img"><img src="@/assets//image/resumeLogin/icon(3).png" alt="" /></div>
            <div class="box-text">在线编辑简历</div>
          </div>

          <div class="box-list" @click="skip('/resume/diagnosis')">
            <div class="box-img"><img src="@/assets//image/resumeLogin/组 43.png" alt="" /></div>
            <div class="box-text">简历诊断</div>
          </div>
        </div>
        <!-- 功能选择区域 -->
        <!-- <div class="box-rest">
          <div class="line-left"></div>
          <div class="function-text">其他</div>
          <div class="line-rigth"></div>
        </div> -->
        <!-- 其他功能选择区域 -->
        <!-- <div class="rest-box">
          <div class="box-list" style="margin-right: 90px">
            <div class="box-img"><img src="@/assets//image/resumeLogin/形状 7.png" alt="" /></div>
            <div class="box-text">简历撰写教程</div>
          </div>
          <div class="box-list" style="margin-right: 110px">
            <div class="box-img"><img src="@/assets//image/resumeLogin/组 43(1).png" alt="" /></div>
            <div class="box-text">反馈建议</div>
          </div>
        </div> -->
        <!-- 其他功能选择区域 -->
        <!-- 关闭按钮 -->
        <div class="removeGuan" @click="close" v-show="control">
          <img src="@/assets/image/resumeLogin/关闭.png" alt="" />
        </div>
        <!-- 关闭按钮 -->
      </div>
    </div>
    <!-- 菜单区域 -->
  </div>
</template>

<script>
export default {
  props: {
    // 控制弹窗组件的关闭按钮的显示
    control: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    skip(router) {
      //如果当前页面的路径为在线编辑页面那就需要进行大字段的添加
      if (this.$router.currentRoute.path === '/resume/edit' && router === '/resume/diagnosis') {
        this.$emit('saveSettings')
      } else {
        if (this.$route.path !== router) this.$router.push(router)
      }
    },
    close() {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped lang="stylus">
.overlay{
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 9999
    .menu-box{
      position absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      .box{
       width: 590px
       height: 580px
       border-radius: 10px
       background-image:url('~@/assets/image/resumeLogin/bg(1).png')
       background-repeat:no-repeat
       position relative
       .box-toptext{
        position absolute
        top 22px
        left: 60px
        .toptext-top{
           font-size: 32px
           font-family: Source Han Sans CN
           font-weight: 500
           color: #174fc2
           margin-bottom: 6px
        }
        .toptext-bottom{
           font-size: 20px
           font-family: Source Han Sans CN
           font-weight: 400
           color: #174fc2
           opacity: 0.9
        }
       }
       .box-function{
         top 169px
         left: 247px
         position relative
        .function-text{
           font-size: 22px
           font-family: Source Han Sans CN
           font-weight: 500
           color: #333333
        }
        .line-left{
          width 160px
          border-top:1px solid #cad8fd
          position absolute
          left: -189px
          top:17px
        }
        .line-rigth{
         width: 160px
         border-top: 1px solid #cad8fd
         position: absolute
         right: 315px
         top: 17px
        }
       }
       .function-box{
        position absolute
        top 225px
        left:57px
        display: flex
        justify-content: space-between;
        width: 464px;
        .box-list{
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          cursor:pointer
          .box-img{
            width: 72px
            height: 72px
            background: #FFFFFF
            box-shadow: 0px 9px 18px 0px rgba(196,214,245,0.15)
            border-radius: 50%
            margin-bottom: 16px
            display: flex
            justify-content: center
            align-items: center
            transition: transform 0.3s ease;
            img{
              width: 31px
              height: 34px
            }
          }
          .box-img:hover{
            transform: scale(1.2)
          }
          .box-text{
            font-size: 18px
            font-family: Source Han Sans CN
            font-weight: 400
            color: #3A3A3A
            letter-spacing: 1px
          }
        }
        .box-list:nth-child(1){
            margin-left: 24px
        }
        .box-list:nth-child(3){
            margin-right: 25px
        }
       }
      .box-rest{
         top: 338px
         left: 276px
         position relative
        .function-text{
           font-size: 22px
           font-family: Source Han Sans CN
           font-weight: 500
           color: #333333
        }
        .line-left{
          width 160px
          border-top:1px solid #cad8fd
          position absolute
          left: -210px
          top:17px
        }
        .line-rigth{
          width 160px
          border-top:1px solid #cad8fd
          position absolute
          right: 337px
          top:17px
        }
       }
        .rest-box{
        position absolute
        top 415px
        left:59px
        display: flex
        .box-list{
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          cursor:pointer
          .box-img{
            width: 72px
            height: 72px
            background: #FFFFFF
            box-shadow: 0px 9px 18px 0px rgba(196,214,245,0.15)
            border-radius: 50%
            margin-bottom: 16px
            display: flex
            justify-content: center
            align-items: center
            transition: transform 0.3s ease;
            img{
              width: 31px
              height: 34px
            }
          }
          .box-img:hover{
            transform: scale(1.2)
          }
          .box-text{
            font-size: 18px
            font-family: Source Han Sans CN
            font-weight: 400
            color: #3A3A3A
          }
        }
       }
       .removeGuan{
        width: 31px;
        eight: 31px;
        ackground: #CCCCCC;
        position absolute
        right -25px
        top:-15px
        cursor: pointer
       }
      }
    }
}
</style>
